<template>
  <div class="FirstNewsroomEdit has-text-centered has-background-white is-full-height is-size-3 is-flex is-aligned-center is-aligned-middle">
    {{ $t('pages.redirect.first_newsroom_edit.content') }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @module FirstNewsroomEdit
 */
export default {
  name: 'FirstNewsroomEdit',
  computed: {
    ...mapGetters('personalNewsrooms', ['firstNewsroom'])
  },
  mounted () {
    if (!this.firstNewsroom) {
      return this.$router.replace({ name: 'home' })
    }
    this.$router.replace({ name: 'newsroom.edit', params: { newsroomSlug: this.firstNewsroom.slug } })
  }
}
</script>
